import React from "react";
import Carousel from "react-bootstrap/Carousel";
import image1 from "../../../../images/carousel-Img/carouselimg1.jpg";
import image7 from "../../../../images/carousel-Img/carouselimg2.jpg";
import image2 from "../../../../images/carousel-Img/carouselimg3.jpg";
import image8 from "../../../../images/carousel-Img/carouselimg4.jpg";
import image3 from "../../../../images/carousel-Img/Carousel-cyber-security.jpg";
import image4 from "../../../../images/carousel-Img/Carousel-cloud.jpeg";
import image5 from "../../../../images/carousel-Img/Carousel-IT Managed Services.jpg";
import image6 from "../../../../images/carousel-Img/Carousel-Systems Integration.jpg";
import image9 from "../../../../images/carousel-Img/consult.jpg";
import "./index.css";
import ApplyButtonMain from "../../../../components/apply-button";

const items = [
  {
    src: image1,
    altText: "Slide 1",
    caption: "Slide 1",
    heading: "",
    heading2: "Pie-Technologies",
    overview:
      "A vibrant, highly innovative service provider company for software development, product development, cloud & cloud native computing, VAPT & cyber security, system integration, IT managed services, IT training & certifications, bootcamps management and HR consultancy.",
    key: 1,
  },
  {
    src: image2,
    altText: "Slide 2",
    caption: "Slide 2",
    heading2: "Software Development",
    overview:
      "Now a days, Web & Mobile applications are essential components of the digital landscape, enabling businesses and individuals to create powerful and user-friendly software solutions. We help our customers to transform their procedural and complex business process into cost effective automated solutions by using the latest cutting edge technologies.",
    key: 2,
  },
  {
    src: image3,
    altText: "Slide 3",
    caption: "Slide 3",
    heading2: "VAPT & Cyber Security",
    overview:
      "Safeguarding your digital assets with vulnerability assessments and penetration testing.",
    key: 3,
  },
  {
    src: image4,
    altText: "Slide 4",
    caption: "Slide 4",
    heading2: "Cloud & Cloud Native Computing",
    overview:
      "Embrace agility, scalability & innovation with Cloud & Cloud Native solutions. Break free from hardware & unleash your creativity. Scale instantly, build modern apps & unlock valuable insights.",
    key: 4,
  },
  {
    src: image5,
    altText: "Slide 5",
    caption: "Slide 5",
    heading2: "IT Managed Services",
    overview: "Proactive and reliable IT support for optimal performance.",
    key: 5,
  },
  {
    src: image6,
    altText: "Slide 6",
    caption: "Slide 6",
    heading2: "System Integrations",
    overview:
      "Seamlessly integrating your existing systems to unlock their full potential.",
    key: 6,
  },
  {
    src: image7,
    altText: "Slide 7",
    caption: "Slide 7",
    heading2: "Training & Certifications",
    overview:
      "Equipping your workforce with the in-demand skills they need to thrive.",
    key: 7,
  },

  {
    src: image8,
    altText: "Slide 8",
    caption: "Slide 8",
    heading2: "Boot Camp Training Management",
    overview: "Expertly managing your bootcamp programs for maximum success.",
    key: 8,
  },
  {
    src: image9,
    altText: "Slide 9",
    caption: "Slide 9",
    heading2: "HR Consultancy Services",
    overview:
      "Optimizing your HR processes for greater efficiency and employee satisfaction.",
    key: 9,
  },
];

const MainPageCarousel = () => {
  return (
    <div
      style={{
        height: "75vh",
        width: "100vw",
        marginTop: "6rem", //10.1 with ticker
        marginBottom: "10rem",
      }}
    >
      {/* <MainTicker /> */}
      <ApplyButtonMain />
      <Carousel interval={3000}>
        {items.map((item, id) => {
          return (
            <Carousel.Item className="position-relative" key={id}>
              <div
                className="main"
                style={{
                  height: "75vh",
                  width: "100vw",
                  background: "black",
                }}
              >
                <img
                  className="d-block w-100"
                  src={item.src}
                  alt={item.altText}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    opacity: "0.5",
                  }}
                />
                <div className="overlay"></div>
              </div>
              <Carousel.Caption
                style={{
                  marginBottom: "5vw",
                  color: "white",
                  textAlign: "start",
                }}
              >
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: "3.5vw",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  {item.heading}
                </h1>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    marginTop: "1vw",
                    fontSize: "2rem",
                    color: "#f7a31c",
                  }}
                >
                  {item.heading2}
                </h1>
                <div className="container">
                  <hr style={{ width: "5vw", border: "3px solid yellow" }} />
                  <h6
                    className="overviewDetail"
                    style={{
                      // width: "32rem",
                      marginTop: "0",
                      fontSize: "1.3rem",
                      textAlign: "justify",
                    }}
                  >
                    {item.overview}
                  </h6>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default MainPageCarousel;
