import React, { useState } from "react";
import MernLogo from "../../images/training-icons/MERN_Icon.png";
import MobileAppDev from "../../images/training-icons/Mobile_Application_Development_Icon.png";
import PythonDev from "../../images/training-icons/Python_Icon.png";
import Javalogo from "../../images/training-icons/java_Icon.png";
import phplogo from "../../images/training-icons/phpLogo.png";
import CNetlogo from "../../images/training-icons/CnetLogo.png";
import Weblogo from "../../images/training-icons/WebDesignLogo.png";
import SeoLogo from "../../images/training-icons/SEOlogo.png";
import AIlogo from "../../images/training-icons/AiLogo.png";
import blockchainlogo from "../../images/training-icons/BlockchainLogo.png";
import cyberlogo from "../../images/training-icons/CyberLogo.png";
import cloudlogo from "../../images/training-icons/CloudLogo.png";
import QAlogo from "../../images/training-icons/QAlogo.png";
import GraphinLogo from "../../images/training-icons/GraphicLogo.png";
import Devlogo from "../../images/training-icons/DevOpsLogo.png";
import Scrumlogo from "../../images/training-icons/Scrumlogo.png";
import adminlogo from "../../images/training-icons/AdminLogo.png";
import Tiktoklogo from "../../images/training-icons/Tiktok.png";
import Amazon from "../../images/training-icons/Amazon.png";
import Shopify from "../../images/training-icons/Shopify 600  75 Perc.png";
import Fiverr from "../../images/training-icons/Fiverr.png";
import ProjectManage from "../../images/training-icons/ProjectManage.png";

import { Row, Col, Button } from "reactstrap";
import { AiOutlineSearch } from "react-icons/ai";
import "./index.css";
import TrainCards from "./_components/Cards";

const data = [
  {
    Image: cloudlogo,
    title: "Cloud Computing & Cloud Native Computing",
    desc: (
      <ul>
        <li>DCA </li>
        <li>CKA</li>
        <li>CKS </li>
        <li>CKAD </li>
        <li>OpenShift </li>
        <li>OpenStack</li>
        <li>AWS </li>
        <li>AZURE </li>
        <li>GCP </li>
      </ul>
    ),
    details: "",
  },
  {
    Image: cyberlogo,
    title: "Cyber Security",
    desc: (
      <ul>
        <li>CEHv12 </li>
        <li>CHFI</li>
        <li>CTIA </li>
        <li>CPANT </li>
        <li>CISSP </li>
        <li>CISM </li>
        <li>CISA </li>
      </ul>
    ),
    details: "",
  },
  {
    Image: Devlogo,
    title: "DevOps",
    desc: (
      <ul>
        <li>Ansible</li>
        <li>CKA</li>
        <li>CKAD </li>
        <li>OpenSift</li>
        <li>AWS Devops</li>
        <li>Azure Devops</li>
      </ul>
    ),
  },
  {
    Image: blockchainlogo,
    title: "Blockchain",
    desc: (
      <ul>
        <li>
          (CBP) <br />
          Certified Blockchain Professional{" "}
        </li>
        <li>
          (CBD) <br />
          Certified Blockchain Developer{" "}
        </li>
        <li>
          (CBSA) <br />
          Certified Blockchain Solution <br /> Architect{" "}
        </li>
        <li>
          (CBBF) <br />
          Certified Blockchain Business <br /> Foundations{" "}
        </li>
      </ul>
    ),
    details: "",
  },
  {
    Image: MernLogo,
    title: "Mern Stack Development (React.Js, Node.Js, Express.Js)",
    desc: (
      <ul className="spaced-list">
        <li>HTML</li>
        <li>CSS</li>
        <li>Bootstrap</li>
        <li>JavaScript (ES6)</li>
        <li>ReactJS</li>
        <li>NextJS</li>
        <li>NodeJS and ExpressJs</li>
        <li>MongoDB</li>
      </ul>
    ),
  },
  {
    Image: MobileAppDev,
    title: "Mobile Application Development (Android, Flutter, React Native)",
    desc: (
      <ul className="spaced-list">
        <li>Development in Android</li>
        <li>Development in React Native</li>
        <li>Development in Flutter</li>
      </ul>
    ),
  },
  {
    Image: ProjectManage,
    title: "Project Management",
    desc: (
      <ul className="spaced-list">
        <li>
          <span style={{ fontWeight: "bold" }}>People 42%</span>
        </li>
        <ol style={{ fontWeight:"normal", letterSpacing:"1px", lineHeight:'12px' }}>
          <li>Empower & Elevate</li>
          <li>Navigate & Collaborate</li>
          <li>Inspire & Mentor</li>
        </ol>
        <li>Process 50%</li>
        <ol style={{ fontWeight:"normal", letterSpacing:"1px", lineHeight:'12px' }}>
          <li>Drive & Deliver</li>
          <li>Navigate & Mitigate</li>
          <li>Empower & Conclude</li>
        </ol>
        <li>. Business Environment 8%</li>
        <ol style={{ fontWeight:"normal", letterSpacing:"1px", lineHeight:'12px' }}>
          <li>Compliance & Value</li>
          <li>Adapt & Enable</li>
          <li>Optimize & Sustain</li>
        </ol>
        {/* Add more details as needed */}
      </ul>
    ),
  },
  {
    Image: PythonDev,
    title: "Python Programming",
    desc: (
      <ul className="spaced-list">
        <li>Introduction</li>
        <li>Lists and tuples</li>
        <li>Functions</li>
        <li>
          Creating and using custom <br /> modules
        </li>
        <li>File input/output</li>
        <li>Object-oriented programming</li>
        <li>Regular expressions</li>
        <li>GUI programming with Tkinter</li>
      </ul>
    ),
  },
  {
    Image: AIlogo,
    title: "Big Data Analytics",
    desc: (
      <ul className="spaced-list">
        <li>clustering</li>
        <li>
          Principal Component Analysis <br />
          (PCA)
        </li>
        <li>Neural Networks</li>
        <li>
          Natural Language Processing
          <br />
          (NLP)
        </li>
        <li>Recommender Systems</li>
        <li>Time Series Analysis</li>
        <li>Big Data Analytics</li>
        <li>Data privacy and security</li>
      </ul>
    ),
  },

  {
    Image: SeoLogo,
    title: "Digital Marketing & SEO",
    desc: (
      <ul className="spaced-list">
        <li> Social Media Marketing</li>
        <li>Social Media Optimization</li>
        <li>Search Engine Optimization</li>
        <li>Google Ads</li>
        <li>Pay Per Click</li>
      </ul>
    ),
  },
  {
    Image: GraphinLogo,
    title: "Graphics Designing & Video Editing",
    desc: (
      <ul className="spaced-list">
        <li>Fundamentals of Graphics</li>
        <li>Social Media Post Designing</li>
        <li>Advertising & Logo Designing </li>
        <li>Video Editing, Merging, Splitting</li>
        <li>Special Effects, Animations</li>
        <li>Sound Effects</li>
      </ul>
    ),
  },

  {
    Image: Fiverr,
    title: "Fiverr And Upwork",
    desc: (
      <ul className="spaced-list">
        <li>Basic to Advance</li>
        <li>Account Creation Professionally</li>
        <li>Ranking Gigs & FIlters Jobs</li>
        <li>Communication with Clients</li>
        <li>Time Management</li>
        <li>
          Scalling Your Freelancing
          <br /> Business
        </li>
      </ul>
    ),
  },

  {
    Image: Javalogo,
    title: "Java Programming",
    desc: (
      <ul className="spaced-list">
        <li>Java programming</li>
        <li>
          Object-oriented programming <br />
          (OOP)
        </li>
        <li>Arrays and collections in Java</li>
        <li>Input/output operations in Java</li>
        <li>Java API classes and methods</li>
        <li>Java GUI programming</li>
        <li>Advanced Java concepts</li>
        <li>Java web development</li>
      </ul>
    ),
  },
  {
    Image: phplogo,
    title: "PHP (Codeigniter / Laravel)",
    desc: (
      <ul className="spaced-list">
        <li>PHP and CodeIgniter</li>
        <li>Controllers</li>
        <li>Models and databases</li>
        <li>
          Form validation and error
          <br /> handling
        </li>
        <li>Sessions and cookies</li>
        <li>Security and authentication</li>
        <li>RESTful API development</li>
        <li>Build and Deploy an Application</li>
      </ul>
    ),
  },
  {
    Image: CNetlogo,
    title: "C#, ASP.NET MVC",
    desc: (
      <ul className="spaced-list">
        <li>C# and the .NET framework</li>
        <li>ASP.NET and web development</li>
        <li>MVC architecture</li>
        <li>Handling requests and responses</li>
        <li>Building dynamic views</li>
        <li>Querying databases</li>
        <li>
          Validating input and handling
          <br /> errors
        </li>
        <li>User Authentication</li>
      </ul>
    ),
  },

  {
    Image: Weblogo,
    title: "Web Design, Web Engineering and Development",
    desc: (
      <ul>
        <li>Figma</li>
        <li>UI/UX Design principles</li>
        <li>Frontend Development</li>
        <li>Web Engineering </li>
        <li>Web Deployment </li>
      </ul>
    ),
  },

  {
    Image: QAlogo,
    title: "Software Quality Assurance",
    desc: (
      <ul>
        <li>Selinium</li>
        <li>Atomated Testing</li>
        <li>Sonarqube </li>
      </ul>
    ),
    details: "",
  },

  {
    Image: Scrumlogo,
    title: "Scrum",
    desc: (
      <ul>
        <li>PSM 1</li>
        <li>PSM 2</li>
        <li>PSP </li>
        <li>PSPD</li>
      </ul>
    ),
  },
  {
    Image: adminlogo,
    title: "System Administration, System Engineering & Networks",
    desc: (
      <ul>
        <li>RHCSA</li>
        <li>RHCE</li>
        <li>Lpic 1</li>
        <li>Lpic 2</li>
        <li>CompTia System+</li>
        <li>CCNA</li>
        <li>CCNP</li>
      </ul>
    ),
  },
  {
    Image: Shopify,
    title: "Shopify (local & International)",
    desc: (
      <ul className="spaced-list">
        <li>Store Creation & Design</li>
        <li>Product Hunting</li>
        <li>Product Listing</li>
        <li>Runing Ads on Tiktok & Facebook</li>
        <li>Dealing With Customers</li>
        <li>Tips & Tricks to Minimize Returns</li>
        <li>COD Accounts</li>
      </ul>
    ),
  },
  {
    Image: Amazon,
    title: "Amazon Virtual Assistant & Dropshipping",
    desc: (
      <ul className="spaced-list">
        <li>Seller Center Creation</li>
        <li>Product Hunting & Listing</li>
        <li>Brand Hunting</li>
        <li>Distributor Hunting</li>
        <li>Product Sourcing</li>
        <li>Order Fulfillment FBA</li>
        <li>A-Z Account Management</li>
      </ul>
    ),
  },
  {
    Image: Tiktoklogo,
    title: "Tiktok Virtual Assistant & Tiktok Shop",
    desc: (
      <ul className="spaced-list">
        <li>Tiktok Shop Creation</li>
        <li>Linking with Tiktok Account</li>
        <li>Product Hunting</li>
        <li>Product Listing Creation</li>
        <li>Product Sourcing</li>
        <li>Order Fulfillment FBA</li>
        <li>A-Z Account Management</li>
      </ul>
    ),
  },
];

const Training = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [showSearch, setShowSearch] = useState(false);
  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  // const [modalStates, setModalStates] = useState(
  //   Array(filteredData.length).fill(false)
  // );

  // const toggleModal = (index) => {
  //   const updatedModalStates = [...modalStates];
  //   updatedModalStates[index] = !updatedModalStates[index];
  //   setModalStates(updatedModalStates);
  // };
  return (
    <>
      <div
        style={{ marginTop: "6.1rem", paddingBottom: "1rem" }}
        className="bg-light"
      >
        <div
          xs={10}
          sm={10}
          md={10}
          lg={10}
          className="d-flex justify-content-center"
        >
          <h1
            className=" mt-4 mb-5"
            style={{
              color: "#333f52",
              marginTop: "2rem",
              fontWeight: "bolder",
            }}
          >
            Training
          </h1>
        </div>
        <TrainCards />
      </div>
      <div
        className="container"
        style={{ paddingBottom: "3rem", paddingTop: "3rem" }}
      >
        <Row className="justify-content-center align-items-center mb-5 ">
          <Col lg={10} md={10} sm={12}>
            <h4
              className="CenteringHeading"
              style={{
                textAlign: "center",
                color: "#333f52",
                fontWeight: "bold",
              }}
            >
              We Provide Professional Trainings In
            </h4>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={1}
            lg={1}
            className="SearchCol d-flex justify-content-end"
          >
            {showSearch ? (
              <input
                style={{
                  marginTop: "1rem",
                  fontWeight: "bolder",
                  height: "2rem",
                  width: "10rem",
                }}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            ) : (
              <Button onClick={toggleSearch}>
                <AiOutlineSearch />
              </Button>
            )}
          </Col>
        </Row>
        <div className="row gap-5 d-flex justify-content-center">
          {filteredData.map((dat, id) => {
            return (
              <div
                key={id}
                className="HoverRow col-lg-3 col-md-3 col-sm-6 col-xs-12"
                style={{
                  cursor: "pointer",
                  width: "21rem",
                }}
              >
                <div className="d-flex justify-content-center">
                  <img
                    className="service-icon"
                    src={dat.Image}
                    alt="loading"
                    style={{
                      width: "18rem",
                      height: "18rem",
                      objectFit: "contain",
                      marginTop: "12px",
                      marginBottom: "12px",
                    }}
                  />
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "black", margin: "1rem 3rem" }}
                >
                  <h4 className=".thatTitle" style={{ color: "black" }}>
                    {dat.title}
                  </h4>
                </div>
                <span id="sci">{dat.desc}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Training;
