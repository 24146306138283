import React from "react";
import Barani from "../../../../images/partner-organizations/BIIT2.png";
import BIS from "../../../../images/partner-organizations/BIS2.png";
import bitsol from "../../../../images/partner-organizations/BITSOL2.png";
import Buraq from "../../../../images/partner-organizations/Buraq.jpg";
import MegaTech from "../../../../images/partner-organizations/MegaTech.jpg";
import MRGT from "../../../../images/partner-organizations/MRGT2.png";
import northern from "../../../../images/partner-organizations/northern.jpeg";
import SNSKIES from "../../../../images/partner-organizations/SNSKIES.png";
import Synavos from "../../../../images/partner-organizations/Synavos.png";
import Slider from "react-slick";
import "./styles.css";

const SlickCarousel = () => {
  const settings = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="slider-container container mb-5">
      <Slider {...settings}>
        <div className="partner-logo">
          <img src={MegaTech} alt="MegaTech" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={Barani} alt="Barani" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={Buraq} alt="Buraq" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={bitsol} alt="Bitsol" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={MRGT} alt="MRGT" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={northern} alt="Northern" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={BIS} alt="BIS" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={SNSKIES} alt="SNSKIES" className="partners-image" />
        </div>

        <div className="partner-logo">
          <img src={Synavos} alt="Synavos" className="partners-image" />
        </div>
      </Slider>
    </div>
  );
};

export default SlickCarousel;
