
import { Route, Routes } from "react-router-dom";
import "./App.css";

import MainNavbar from "./components/navbar";
// import ImagePopup from "./components/popup/popup";
import MainPage from "./page/mainpage";
import Events from "./page/events";
import Training from "./page/training";
import Services from "./page/services";
import Products from "./page/products";
import Portfolio from "./page/portfolio";
import ContactUs from "./page/contact-us";
import Footer from "./components/footer";
import Pearson from "./page/pearson";
// import Footer2 from "./components/footer-new";

// import ProfileSelect from "./components/pages/profileBuilder/ProfileSelect";
// import AdminDash from "./components/pages/admin/AdminDash";
// import ShowOneImage from "./components/pages/admin/ShowOneImage";

function App() {
  // const [isModalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   // Check if the modal has been opened before in local storage
  //   const hasModalOpenedBefore = localStorage.getItem("hasModalOpenedBefore");

  //   if (!hasModalOpenedBefore) {
  //     // If the modal hasn't been opened before, open it after 2 seconds
  //     const timeout = setTimeout(() => {
  //       setModalOpen(true);
  //       // Set a flag in local storage indicating that the modal has been opened
  //       localStorage.setItem("hasModalOpenedBefore", "true");
  //     }, 2000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, []);

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainNavbar />}>
          <Route
            path="/"
            element={
              <>
                <MainPage />
                <Footer />
              </>
            }
          />
          <Route
            path="/latestnews"
            element={
              <>
                <Events />
                <Footer />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <ContactUs />
                <Footer />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <Services />
                <Footer />
              </>
            }
          />
          <Route
            path="/training"
            element={
              <>
                <Training />
                <Footer />
              </>
            }
          />
          <Route
            path="/products"
            element={
              <>
                <Products />
                <Footer />
              </>
            }
          />
          <Route
            path="/portfolio"
            element={
              <>
                <Portfolio />
                <Footer />{" "}
              </>
            }
          />
          <Route
            path="/pearson"
            element={
              <>
                <Pearson />
                <Footer />{" "}
              </>
            }
          />
          {/* <Route path="/trainercareer" element={<><TrainerCareer /><Footer /></>} /> */}
          {/* <Route path="/developercareer" element={ <><DeveloperCareer /><Footer /></>}/> */}
          {/* <Route path="/profile" element={<><ProfileSelect /><Footer /></>}/> */}
        </Route>

        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/verificationRequired" element={<VerifyFirst />} /> */}
        {/* <Route path="/adminDashboard" element={<AdminDash />} /> */}
        {/* <Route path="/showImage/:id" element={<ShowOneImage />} /> */}
      </Routes>
      {/* <ImagePopup isOpen={isModalOpen} closeModal={closeModal} /> */}
    </div>
  );
}

export default App;
